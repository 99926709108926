import { useEffect } from "react";
import { JA, JK } from "../data/music";

const Music = () => {
  useEffect(() => {
    document.title = "JAES Made It | Music";
  }, []);

  return (
    <div id="main">
      <section className="p-5 mb-4 site-title">
        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold">Music</h1>
          <p className="col-md-8 fs-4">[recording] [mastering] [publishing]</p>
          <a
            href="https://www.jhonnierandrey.com/jmusicenter/"
            className="btn btn-primary default-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Submit a demo
          </a>
        </div>
      </section>
      <section className="music-box">
        <div className="title">
          <h1>J Körber</h1>
        </div>
        {JK.map((project, index) => (
          <div
            className="card bg-dark text-white"
            style={{ backgroundImage: `url(${project.img})` }}
            key={index}
          >
            <div className="card-img-overlay">
              <div className="card-titles">
                <h5 className="card-title">
                  <i className="fas fa-compact-disc"></i> {project.name},{" "}
                  {project.description}
                </h5>
                <p className="card-text">
                  <i className="far fa-calendar-alt"></i> {project.release}
                </p>
                <p className="card-text">
                  <i className="fas fa-headphones-alt"></i> Listen on{" "}
                  <a
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {project.listeOn}
                  </a>
                </p>
              </div>
            </div>
          </div>
        ))}
      </section>
      <hr />
      <section className="music-box">
        <div className="title">
          <h1>JhonnierAndrey</h1>
        </div>
        {JA.map((project, index) => (
          <div
            className="card bg-dark text-white"
            style={{ backgroundImage: `url(${project.img})` }}
            key={index}
          >
            <div className="card-img-overlay">
              <div className="card-titles">
                <h5 className="card-title">
                  <i className="fas fa-compact-disc"></i> {project.name},{" "}
                  {project.description}
                </h5>
                <p className="card-text">
                  <i className="far fa-calendar-alt"></i> {project.release}
                </p>
                <p className="card-text">
                  <i className="fas fa-headphones-alt"></i> Listen on{" "}
                  <a
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {project.listeOn}
                  </a>
                </p>
              </div>
            </div>
          </div>
        ))}
        <div className="title"></div>
      </section>
      <hr />
      <section className="music-socials">
        <div>
          <h5>Coming soon on</h5>
          <div className="social-icons">
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCPEwt3u6UIbsetRB9HkcaOQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://soundcloud.com/jaesmadeit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-soundcloud"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <hr />
    </div>
  );
};

export default Music;
