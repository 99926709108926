import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "JAES Made It | Preview";
  });

  return (
    <div className="home">
      <img
        src="https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000185-1907b1907f/450/jaes%20made%20it%20promo.png"
        alt="JAES Made It"
      />
      <br />
      <br />
      <h2>coming soon.</h2>
    </div>
  );
}

export default App;
