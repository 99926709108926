export const BOOKS = [
  {
    title: "Oscuridad",
    subtitle: "El reflejo de la luz de la luna",
    writer: "Vhelkan Zoto",
    description:
      "A story told between dramatic poetic verses that tries to express the feeling of two merged beings wanting to separate one from the other. While a measure of time describes his life, feelings, desires and way of seeing the world, it tries to introduce humanity to the very reality of being, trying to raise awareness of the true value of life and the meaning of death.",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000005-a04a8a1440/700/Oscuridad%20-Vhelkan%20Zoto%20-%20jhonnierandrey%20-%20libro%20-%20book.jpg",
    copyright:
      "© Vhelkan Zoto & JhonnierAndrey | Equipo de Publicaciones aBPC | Colombia | Spanish Edition | 2015",
    url: "https://www.autoreseditores.com/libro/23215/vhelkan-zoto/oscuridad.html",
  },
  {
    title: "Estados",
    subtitle: "Cercanos a una obsesión",
    writer: "Jhonnier Andrey",
    description:
      "This is a story very close to reality, inspired by moments of happiness and sadness that I was able to live in some not too distant time, a story that combines reality with fantasies that were appearing over time in which we both share more than any once I dreamed.",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000002-05d7c06d0c/Estados%20-%20jhonnierandrey%20-%20book%20-%20libro.jpg",
    copyright:
      "© JhonnierAndrey | Equipo de Publicaciones aBPC | Colombia | Spanish Edition | 2014",
    url: "/#",
  },
];

export const CONTRIBUTIONS = [
  {
    title: "Yo y mi soledad",
    subtitle: "",
    writer: "Vhelkan Zoto",
    description: "",
    img: "./assets/img/books/yo-y-mi-soledad.png",
    copyright:
      "© Vhelkan Zoto & JAES Made It | JAES Made It | Argentina | Spanish Edition | 2020",
    url: "https://www.autoreseditores.com/libro/23218/vhelkan-zoto/yo-y-mi-soledad.html",
  },
  {
    title: "La Razón Del Lobo",
    subtitle: "",
    writer: "Vhelkan Zoto",
    description: "",
    img: "./assets/img/books/la-razon-del-lobo.png",
    copyright:
      "© Vhelkan Zoto & JAES Made It | JAES Made It | Argentina | Spanish Edition | 2020",
    url: "https://www.autoreseditores.com/libro/23217/vhelkan-zoto/la-razon-del-lobo.html",
  },
  {
    title: "Ella",
    subtitle: "",
    writer: "Vhelkan Zoto",
    description: "",
    img: "./assets/img/books/ella.png",
    copyright:
      "© Vhelkan Zoto & JAES Made It | JAES Made It | Argentina | Spanish Edition | 2020",
    url: "https://www.autoreseditores.com/libro/23216/vhelkan-zoto/ella.html",
  },
  {
    title: "Vhaljara",
    subtitle: "",
    writer: "Vhelkan Zoto",
    description: "",
    img: "./assets/img/books/vhaljara.png",
    copyright:
      "© Vhelkan Zoto & JAES Made It | JAES Made It | Argentina | Spanish Edition | 2020",
    url: "https://www.autoreseditores.com/vhelkanzoto",
  },
  {
    title: "Tierra De Dioses",
    subtitle: "Parte 1",
    writer: "Vhelkan Zoto",
    description: "",
    img: "./assets/img/books/tierra-de-dioses.png",
    copyright:
      "© Vhelkan Zoto & JAES Made It | JAES Made It | Argentina | Spanish Edition | 2020",
    url: "https://www.autoreseditores.com/vhelkanzoto",
  },
  {
    title: "Sangre De Un Poeta",
    subtitle: "Recopilación",
    writer: "Vhelkan Zoto, Maleja Rodas, Jhonnier Zapata",
    description: "",
    img: "./assets/img/books/sangre-de-un-poeta.png",
    copyright:
      "© Vhelkan Zoto & JAES Made It | JAES Made It | Argentina | Spanish Edition | 2020",
    url: "https://www.autoreseditores.com/libro/23220/vhelkan-zoto/sangre-de-un-poeta.html",
  },
];
