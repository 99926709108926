export const JA = [
  {
    name: "Century",
    description: "Single",
    release: "May 28, 2016",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000087-847d685762/450/Century%20%20-%20JhonnierAndrey%20-%20Single%20-%20Cover-6.jpg",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/century",
  },
  {
    name: "All The Memories",
    description: "Album",
    release: "July 1, 2016",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000091-25bae26ba3/450/Memories%20Album%20Cover%20jhonnierandrey%20music-8.png",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/sets/all-the-memories",
  },
  {
    name: "Motionless",
    description: "EP",
    release: "May 12, 2017",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000088-df441e041e/450/20170304_035023000_iOS-9.jpg",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/sets/motionless",
  },
  {
    name: "Fate",
    description: "Single",
    release: "January 08, 2018",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000093-3e32f3f1eb/450/Fate%20%20-%20JhonnierAndrey%20-%20SINGLE%20Cover-6.png",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/fate",
  },
  {
    name: "With All Your Heart",
    description: "Single",
    release: "January 08, 2018",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000092-e5237e61ef/450/With%20All%20Our%20Heart%20-%20JhonnierAndrey%20-%20SINGLE%20Cover-8.jpg",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/with-all-our-heart",
  },
  {
    name: "Reality",
    description: "Single",
    release: "February 08, 2018",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000090-cdb34ceb6b/450/Reality%20-%20JhonnierAndrey%20-%20SINGLE%20Cover-8.jpg",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/reality",
  },
  {
    name: "This One's 4",
    description: "Album",
    release: "March 12, 2018",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000089-597c55a7ea/450/TO4U%20Album%20Cover-1-8.jpg",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/sets/this-ones-4u",
  },
  {
    name: "0312",
    description: "Single",
    release: "July 1, 2019",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000120-dc069dd001/450/0312.png",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/0312a",
  },
  {
    name: "Pattern",
    description: "Single",
    release: "January 30, 2020",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000179-ca50cca50f/700/Pattern%20-%20Cover.png",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/sets/pattern",
  },
  {
    name: "Aura",
    description: "Album",
    release: "October 20, 2020",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000192-dfbe6dfbe9/450/aura%20-%20cover.png",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jhonnierandrey/sets/aura",
  },
];

export const JK = [
  {
    name: "Ökosystem",
    description: "Album",
    release: "March 12, 2020",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000181-4271f42723/450/O%CC%88.png",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jaesmadeit/sets/j-korber-okosystem",
  },
  {
    name: "night mode",
    description: "Album",
    release: "October 23, 2020",
    img: "https://d6scj24zvfbbo.cloudfront.net/1e7d9ca57389b5a92c169de46002c3b1/200000182-5c3cf5c3d2/450/nightmode.png",
    listeOn: "SoundCloud",
    url: "https://soundcloud.com/jaesmadeit/sets/j-korber-night-mode",
  },
];
