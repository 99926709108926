import { Navbar, NavbarBrand, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Header = () => {
  return (
    <header id="header">
      <Navbar expand="lg" variant="" collapseOnSelect>
        <div className="container-fluid">
          <LinkContainer to="/">
            <NavbarBrand>JAES Made It</NavbarBrand>
          </LinkContainer>
          <Navbar.Toggle as="button">
            <i className="fas fa-bars"></i>
          </Navbar.Toggle>

          <Navbar.Collapse>
            <Nav as="ul">
              <Nav.Item as="li">
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item as="li">
                <LinkContainer to="/projects">
                  <Nav.Link>Projects</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item as="li">
                <LinkContainer to="/mobile-apps" className="new-preview">
                  <Nav.Link>
                    Apps <span className="badge rounded-pill bg-dark">new</span>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item as="li">
                <LinkContainer to="/music">
                  <Nav.Link>Music</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item as="li">
                <LinkContainer to="/books">
                  <Nav.Link>Books</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item>
                <LinkContainer to="/contact">
                  <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
