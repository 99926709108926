import { useEffect } from "react";

const NotFound = () => {
  // const errorLocation = location.pathname.split("/")[1];
  const errorLocation = "Unknown";

  useEffect(() => {
    document.title = "JAES Made It | Not Found";
  });

  return (
    <div className="home">
      <img src="./assets/img/not-found.png" alt="Not Found" />
      <br />
      <br />
      <h2>
        Looks like <i>"{errorLocation}"</i> is not available at the moment.
      </h2>
      <a href="/projects" className="btn btn-primary default-link">
        Go to projects.
      </a>
    </div>
  );
};

export default NotFound;
