import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { BOOKS, CONTRIBUTIONS } from "../data/books";

const Books = () => {
  useEffect(() => {
    document.title = "JAES Made It | Books";
  }, []);

  return (
    <div id="main">
      <section className="p-5 mb-4 site-title">
        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold">Books</h1>
          <p className="col-md-8 fs-4">Layout planning, publishing & more.</p>
          <a href="/contact" className="btn btn-primary default-link">
            Learn more
          </a>
        </div>
      </section>
      <section className="books-box">
        {BOOKS.map((book, index) => (
          <article className="card mb-3" key={index}>
            <div className="row g-0">
              <div className="card-book-img col-md-4">
                <LazyLoadImage effect="blur" src={book.img} alt={book.title} />
              </div>
              <div className="col-md-7">
                <div className="card-body">
                  <h5 className="card-title">
                    {book.title} by {book.writer}{" "}
                  </h5>
                  <h6>"{book.subtitle}"</h6>
                  <hr />
                  <p className="card-text">{book.description}</p>
                  <p className="card-text">
                    <small className="text-muted"> {book.copyright} </small>
                  </p>
                  <a
                    href={book.url}
                    className="btn btn-primary default-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get book
                  </a>
                </div>
              </div>
            </div>
          </article>
        ))}
      </section>
      <section className="books-box">
        <article className="card mb-3">
          <div className="row g-0">
            <h2 className="text-center">Proud designer contributor on:</h2>
            <div className="card-book-img books-contribution">
              {CONTRIBUTIONS.map((book, index) => (
                <a href={book.url} target="_blank" rel="noreferrer" key={index}>
                  <LazyLoadImage
                    effect="blur"
                    src={book.img}
                    alt={book.title}
                    title={`${book.title} - ${book.writer}`}
                  />
                </a>
              ))}
            </div>
          </div>
        </article>
      </section>
    </div>
  );
};

export default Books;
