import ReactDOM from "react-dom/client";
import "./styles/main.scss";
import App from "./App";

import { Header, Footer } from "./components";

import { Projects, Music, Books, Contact, NotFound, MobileApps } from "./pages";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

const routing = (
  <Router>
    <div id="wrapper">
      <Header />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/mobile-apps" element={<MobileApps />} />
        <Route path="/music" element={<Music />} />
        <Route path="/books" element={<Books />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(routing);
