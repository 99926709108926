import { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    document.title = "JAES Made It | Contact";
  }, []);

  return (
    <div id="main">
      <section className="p-5 mb-4 site-title">
        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold">Contact</h1>
          <p className="col-md-8 fs-4">
            We'd love to hear from you, send us a message!
          </p>
        </div>
        <form name="contact" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Name"
              name="name"
              required
            />
            <label htmlFor="floatingInput">Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              name="email"
              required
            />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className="form-floating mb-3">
            <textarea
              className="form-control"
              placeholder="Leave a message here"
              id="floatingTextarea"
              name="message"
              required
            ></textarea>
            <label htmlFor="floatingTextarea">Your message</label>
          </div>
          <button type="submit" className="btn btn-primary default-link">
            Send message now
          </button>
        </form>
      </section>
    </div>
  );
};

export default Contact;
