export const PROJECTS = [
  {
    name: "ADAM N0M4D Landing Page",
    description: "ReactJS application built with TypeScript.",
    technologies: "ReactJS, TS, SASS",
    img: "./assets/img/projects/adam-n0m4d.jpg",
    git: "https://github.com/jhonnierandrey/",
    url: "https://www.adamn0m4d.com/",
  },
  {
    name: "Vhelkan Zoto Landing Page",
    description: "ReactJS application built with TypeScript.",
    technologies: "ReactJS, TS, SASS",
    img: "./assets/img/projects/vhelkan-zoto.jpg",
    git: "https://github.com/jhonnierandrey/",
    url: "https://www.vhelkanzoto.com/",
  },
  {
    name: "TMDB Popular Movies",
    description: "NextJS application using the latest NextJS APIs.",
    technologies: "NextJS, TS, SASS/CSS",
    img: "./assets/img/projects/tmbdpopularmovies.jpg",
    git: "https://github.com/jhonnierandrey/tmdb-nxt",
    url: "https://jmi-tmdb-nxt.vercel.app/",
  },
  {
    name: "Majal Landing Page",
    description: "ReactJS application serving as landing page for Majal band.",
    technologies: "ReactJS, JS, HTML, SASS/CSS",
    img: "./assets/img/projects/majal.jpg",
    git: "https://github.com/jhonnierandrey/",
    url: "https://majal.uy/",
  },
  {
    name: "Rick And Morty with Vue",
    description: "Vue App to access and filter the Rick And Morty characters.",
    technologies: "VueJS, JS, HTML, SASS/CSS",
    img: "./assets/img/projects/rxmvw.png",
    git: "https://github.com/jhonnierandrey/rxm-vw",
    url: "https://jmi-rxm-vw.vercel.app/",
  },
  {
    name: "Canceling Plans Excuse Generator",
    description:
      "ReactApp to generate random or custom excuses based on a meme.",
    technologies: "ReactJS, JS, HTML, SASS/CSS",
    img: "./assets/img/projects/excusegenerator.jpg",
    git: "https://github.com/jhonnierandrey/cpeg-rx",
    url: "https://jmi-cpeg-rx.vercel.app/",
  },
  {
    name: "Simple Web Scraper",
    description: "Web scraper for headlines + direct urls from news sites.",
    technologies: "NodeJS with EJS, JS, HTML, CSS",
    img: "./assets/img/projects/simplescraper.png",
    git: "https://github.com/jhonnierandrey/scrp-nd",
    url: "https://jmi-prjct-scrp.herokuapp.com/",
  },
  {
    name: "Disney+ Clone",
    description: "Disney+ clone build on top of NextJS.",
    technologies: "NextJS, JS, GraphQL, SCSS, HTML, CSS",
    img: "./assets/img/projects/disneyplusclone.jpg",
    git: "https://github.com/jhonnierandrey/shld-nxt",
    url: "https://jmi-disneyplus-clone.vercel.app/",
  },
  {
    name: "ReactBank (frontend)",
    description: "FullStack banking app concept.",
    technologies: "ReactJS, JS, SCSS, HTML, CSS",
    img: "./assets/img/projects/reactbank-front.jpg",
    git: "https://github.com/jhonnierandrey/reactbank",
    url: "https://reactbank-front-end.netlify.app/",
  },
  {
    name: "ReactBank (backend)",
    description:
      "Backend that handles users and transactions requests as an API.",
    technologies: "Python, Flask, SQLite, HTML, CSS",
    img: "./assets/img/projects/reactbank-back.jpg",
    git: "https://github.com/jhonnierandrey/reactbank-bk",
    url: "https://reactbank-back-end.herokuapp.com/",
  },
  {
    name: "Just Time Signals",
    description: "FullStack web app for automatic trading signals.",
    technologies: "ReactJS, NodeJS, MySQL, Python, JS, SCSS, HTML, CSS",
    img: "./assets/img/projects/justtimesignals.png",
    git: "https://github.com/jhonnierandrey/",
    url: "https://jaesmadeit-project-slytherin.netlify.app/",
  },
  {
    name: "Matrix Code Terminal",
    description:
      "React App created using TypeScript that generates the Matrix Code Rain.",
    technologies: "TypeScript, ReactJS, JS, SCSS, HTML, CSS",
    img: "./assets/img/projects/matrixcodeterminal.png",
    git: "https://github.com/jhonnierandrey/matrix",
    url: "https://jaesmadeit-project-matrix.herokuapp.com/",
  },
  {
    name: "Imaginäre App",
    description:
      "React App designed to search for free images using the Pixabay Api.",
    technologies: "ReactJS, JS, SCSS, HTML, CSS",
    img: "./assets/img/projects/imaginareapp.png",
    git: "https://github.com/jhonnierandrey/dasimaginare",
    url: "https://dasimaginare.netlify.app/",
  },
  {
    name: "The Covid Board",
    description:
      "Dashboard for Coronavirus disease (COVID-19), uses the Johns Hopkins University data as an API.",
    technologies: "ReactJS, JS, SCSS, HTML, CSS",
    img: "./assets/img/projects/thecovidboard.png",
    git: "https://github.com/jhonnierandrey/covid-board",
    url: "https://thecovidboard.netlify.app/",
  },
  {
    name: "Personal Portfolio",
    description: "Personal portfolio for other projects.",
    technologies: "JS, HTML, CSS",
    img: "./assets/img/projects/portfolio.png",
    git: "https://github.com/jhonnierandrey/",
    url: "https://www.jazr.me/",
  },
  {
    name: "JhonnierAndrey",
    description: "Website designed for a musical project.",
    technologies: "JS, HTML, CSS",
    img: "./assets/img/projects/jhonnierandrey.png",
    git: "https://github.com/jhonnierandrey/",
    url: "https://www.jhonnierandrey.com/",
  },
];
