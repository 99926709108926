export const MOBILE_APPS = [
  {
    name: "iChatGPT iOS App",
    description:
      "iChatGPT iOS App that uses the OpenAI API to have interactive chats, built with SwiftUI using Core Data for history persistence.",
    technologies: "Swift, SwiftUI",
    img: "./assets/img/projects/mobile-apps/ichatgpt-preview.png",
    git: "https://github.com/jhonnierandrey/swiChatGPT",
    url: "/#",
  },
  {
    name: "Expense Tracker",
    description:
      "Made with React Native + TypeScript to handle expenses using Async Storage.",
    technologies: "React Native, TypeScript",
    img: "./assets/img/projects/mobile-apps/expensetracker-preview.png",
    git: "https://github.com/jhonnierandrey/rnExpenseTracker",
    url: "/#",
  },
  {
    name: "Calories Tracker",
    description:
      "Calories Tracker App with SwiftUI using Core Data for data persistence.",
    technologies: "Swift, SwiftUI",
    img: "./assets/img/projects/mobile-apps/caloriestracker-preview.png",
    git: "https://github.com/jhonnierandrey/swCaloriesTracker",
    url: "/#",
  },
  {
    name: "Landmarks - iOS, WatchOS and macOS App",
    description:
      "Landmarks App built with SwiftUI, with Apple Watch and Mac extension as part of the Introducing SwiftUI tutorial by Apple",
    technologies: "Swift, SwiftUI",
    img: "./assets/img/projects/mobile-apps/landmarks/landmark-multidevice-preview.png",
    git: "https://github.com/jhonnierandrey/swLandmarks",
    url: "/#",
  },
  {
    name: "Reviews App",
    description:
      "Basic Reviews App made with Expo and React Native to manage simple form validations.",
    technologies: "Expo, React Native, TypeScript",
    img: "./assets/img/projects/mobile-apps/reviewsapp-preview.png",
    git: "https://github.com/jhonnierandrey/ernReviewsApp",
    url: "/#",
  },
  {
    name: "LittleLemon - Reservation App",
    description:
      "Simple iOS to schedule reservations in an imaginary restaurant called LittleLemon part of the Meta iOS certification",
    technologies: "Swift, SwiftUI",
    img: "./assets/img/projects/mobile-apps/littlelemon-preview.png",
    git: "https://github.com/jhonnierandrey/swLittleLemon",
    url: "/#",
  },
  {
    name: "Crypto Viewer",
    description:
      "App to visualize crypto coins, current prices and change during last 24hrs",
    technologies: "React Native, Redux, TypeScript",
    img: "./assets/img/projects/mobile-apps/cryptoviewer-preview.png",
    git: "https://github.com/jhonnierandrey/rnCryptoViewer",
    url: "/#",
  },
  {
    name: "Calculator Clone - Classic iOS App",
    description: "Default calculator app clone from iOS 16 built with SwiftUI",
    technologies: "Swift, SwiftUI",
    img: "./assets/img/projects/mobile-apps/calculatorclone-preview.png",
    git: "https://github.com/jhonnierandrey/swCalculatorClone",
    url: "/#",
  },
  {
    name: "VetApp - Veterinary Appointment App",
    description:
      "React Native application built with TypeScript for appointment management.",
    technologies: "React Native, TypeScript",
    img: "./assets/img/projects/mobile-apps/vetapp-preview.png",
    git: "https://github.com/jhonnierandrey/rnVetAppt",
    url: "/#",
  },
];
