import { useEffect } from "react";
import { MOBILE_APPS } from "../data/mobileapps";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const MobileApps = () => {
  useEffect(() => {
    document.title = "JAES Made It | Mobile Apps";
  }, []);

  return (
    <div id="main">
      <section className="p-5 mb-4 site-title">
        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold">Mobile Apps</h1>
          <p className="col-md-8 fs-4">
            <i>Preview.</i>
          </p>
          <a href="/contact" className="btn btn-primary default-link">
            Learn more
          </a>
        </div>
      </section>

      <section className="mobileapps-box">
        {MOBILE_APPS.map((project, index) => (
          <article className="card" key={index}>
            <LazyLoadImage
              effect="blur"
              src={project.img}
              className="card-img-top"
              alt={project.name}
              loading="lazy"
            />
            <div className="card-body">
              <h5 className="card-title">{project.name}</h5>
              <p className="card-text">{project.description}</p>
              <p className="card-text">
                <a
                  className="link-text"
                  href={project.git}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-github-square"></i>{" "}
                  {project.technologies}{" "}
                </a>
              </p>
              <a
                href={project.git}
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                See on GitHub
              </a>
            </div>
          </article>
        ))}
      </section>
    </div>
  );
};

export default MobileApps;
