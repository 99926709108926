const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer id="footer">
      <div className="social-icons">
        <ul>
          <li>
            <a
              href="http://be.net/jhonnierandrey"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-behance-square"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/jaesmadeit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.twitter.com/jaesmadeit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-square-x-twitter"></i>
            </a>
          </li>
          <li>
            <a
              href="http://instagram.com/jaesmadeit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-text">&copy; {currentYear} | JAES Made It</div>
    </footer>
  );
};

export default Footer;
